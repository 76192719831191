import React, {Component} from 'react'
import { translate, Trans } from "react-i18next";
import {Helmet} from "react-helmet"
import SearchBoxSide from '../../components/posts/SearchBoxSide'
import PostList from '../../components/posts/PostList'
import Pagination from '../../components/Pagination'
import NewsLetter from '../../components/NewsLetter'


import {POST_LIST} from '../../config/mocks'
// import ReactCountdownClock from 'react-countdown-clock'
class Listings extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		const {t} = this.props
		return (
			<React.Fragment>
				<Helmet>
					{/*facebook*/}
					<title> {"Tìm kiếm thông tin mua bán xe hơi tại Việt Nam"} </title>
					<meta property="og:title" content="Tìm kiếm thông tin mua bán xe hơi tại Việt Nam" />
					<meta property="og:description" content="Tìm kiếm thông tin mua bán xe hơi tại Việt Nam"/>
					<meta property="og:image" content="http://topauto.vn/images/logo.svg"/>
					<meta property="og:url" content="http://topauto.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content="Tìm kiếm thông tin mua bán xe hơi tại Việt Nam"/>
					<meta name="twitter:description" content="Tìm kiếm thông tin mua bán xe hơi tại Việt Nam"/>
					<meta name="twitter:image" content="http://topauto.vn/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
				</Helmet>
				<div className="container">
					<div className="row">
						<div className="col-md-4">
							<div className="row m-3 p-0">
								<h3>{t('find_vehicles')}</h3>
							</div>
							<div className="row m-3">
								<div className="col-12 p-0">
									<SearchBoxSide />
								</div>
							</div>
						</div>
						<div className="col-md-8">
							<PostList items={POST_LIST}/>
							<nav>
								<Pagination />
							</nav>
						</div>
					</div>
				</div>
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default translate("translations")(Listings)
