import React, {Component} from 'react'
import { Form, Button } from 'react-bootstrap';
import Section from '../components/Section'

class Contact extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		return (
			<React.Fragment>
			<main role="main">
				<Section title="Contact Us for Support & Sales" subTitle="We guarantee to answer all emails within one business day"/>
				<section className="section-contact" id="section-six">
            	<div className="container">
                	 <header role="title-page" className="text-center">
                        <h4>Get in touch</h4>
                        <h2>Have any questions? Our team will happy to<br/>answer your questions.</h2>
                    </header>
                    <div className="contact-form">
                        <div id="message"></div>
                            <form name="cform" id="cform">
                            	<div className="container">
                            		<div className="row">
                            			<div className="col-md-6 col-lg-6 col-sm-12">

                                	<input name="name" id="name" type="text" placeholder="Full Name (*)"/>
                                </div>
                                <div className="col-md-6 col-lg-6 col-sm-12">

                                	<input name="email" id="email" type="email" placeholder="Email Address(*)"/>

                                </div>
                            		</div>
                                </div>
                                <div className="clearfix"></div>

                                <textarea name="comments" id="comments" cols="" rows="" placeholder="Your message (*)"></textarea>

                                <div className="clearfix"></div>
                                <div id="simple-msg">(*) is required fields</div>
                                <input name="" type="button" value="Send mail"/>

                                <div id="simple-msg"></div>

                        	</form>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <div className="map-wrapper">
                	<div id="surabaya"></div>
                </div>

            </section>
			</main>
				
			</React.Fragment>
		)
	}
}
export default Contact;
/*
<Form>
					<Form.Group controlId="formBasicEmail">
						<Form.Label>Email address</Form.Label>
						<Form.Control type="email" placeholder="Enter email" />
						<Form.Text className="text-muted">We'll never share your email with anyone else.
						</Form.Text>
					</Form.Group>
					<Form.Group controlId="formBasicPassword">
						<Form.Label>Password</Form.Label>
						<Form.Control type="password" placeholder="Password" />
						</Form.Group>
					<Form.Group controlId="formBasicChecbox">
						<Form.Check type="checkbox" label="Check me out" />
					</Form.Group>
					<Button variant="primary" type="submit">
					Submit
					</Button>
				</Form>
*/