import React from 'react'
import { translate } from 'react-i18next'
const PostInCityListItem = ({t, item}) => (
	<div className="card border-0 text-center col-xl-2 col-md-4 col-12" >
		<img className="card-img-top" src={item.imageURL} alt=""/>
		<div className="card-body">
			<address className="card-title">{item.district}</address>
			<div className="card-text">
				{item.price}
			</div>
		</div>
	</div>
)
export default translate("translations")(PostInCityListItem)