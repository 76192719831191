import React, {Component} from 'react'
import { translate, Trans } from "react-i18next";
import {Helmet} from "react-helmet"
import PrivacyBreadcrumbsSection from '../components/PrivacyBreadcrumbsSection'
import NewsLetter from '../components/NewsLetter'

class Privacy extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		return (
			<React.Fragment>
				<Helmet>
					{/*facebook*/}
					<title> {"Chính sách bảo mật - TopAuto"} </title>
					<meta property="og:title" content="Chính sách bảo mật - TopAuto" />
					<meta property="og:description" content="Chính sách bảo mật - TopAuto"/>
					<meta property="og:image" content="http://topauto.vn/images/logo.svg"/>
					<meta property="og:url" content="http://topauto.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content="Chính sách bảo mật - TopAuto"/>
					<meta name="twitter:description" content="Chính sách bảo mật - TopAuto"/>
					<meta name="twitter:image" content="http://topauto.vn/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
				</Helmet>
				<PrivacyBreadcrumbsSection />
				<div className="section">
					<div className="container">
						<div className="row">
							<div className="col-12 section_title">
								<p style={{textAlign: "justify"}} >
									<a href="http://topauto.vn">Topauto.vn</a> là website thuộc sở hữu và vận hành bởi công ty <a href="http://toplabs.io" target="_blank"> TopLabs </a>. 
									Đây là chính sách bảo mật trình bày cách thức chúng tôi thu thập, sử dụng, xử lý và bảo vệ dữ liệu cá nhân mà bạn cung cấp trong quá trình sử dụng các dịch vụ của TopAuto thông qua website hoặc ứng dụng điện thoại.
									Dữ liệu cá nhân có nghĩa là dữ liệu có thể trình bày đúng hoặc không về một chủ thể xác định nào đó. Các dữ liệu thu thập không giới hạn trên website và ứng dụng di động.
									Khi vào website này, bạn đồng ý và thoả thuận về việc thu thập, sử dụng và xử lý dữ liệu cá nhân của bạn theo chính bảo mật hoặc/và điều khoản sử dụng của website. 
									Nếu bạn không đồng ý với các điều khoản sau, vui lòng thoát khỏi website ngay lập tức.
								</p>
								<br />
								<div>
									<h5>1. Thu thập dữ liệu</h5>
									<p style={{textAlign: "justify"}} >
									- Chúng tôi thu thập dữ liệu mà bạn cung cấp trong quá trình sử dụng Web hoặc ứng dụng. Thông tin này bao gồm: tên của bạn, thông tin nhận dạng người dùng và thông tin đăng nhập TopAuto, địa chỉ, số điện thoại, địa chỉ email.
									</p>
									<p style={{textAlign: "justify"}} >
									- Chúng tôi có thể yêu cầu bạn cung cấp sở thích cũng như trải nghiệm của bạn khi sử dụng dịch vụ của chúng tôi. Ngoài ra, chúng tôi cũng thu thập thông tin không thể nhận dạng cá nhân bao gồm nhưng không giới hạn địa chỉ IP, vị trí địa lý, hệ điều hành, thông tin thiết bị, quốc tịch, tùy chọn tìm kiếm, cũng như các dữ liệu liên quan đến sử dụng Internet chung khác. 
									</p>
								</div>
								<br />
								<div>
									<h5>2. Sử dụng dữ liệu</h5>
									<p style={{textAlign: "justify"}} >
									- Chúng tôi có thể sử dụng các đữ liệu liên quan đến bạn để cung cấp dịch vụ tốt nhất cho trải nghiệm của bạn bao gồm nhưng không giới hạn: i) đăng tin và quản lý tin; ii) quản lý kinh doanh bất động sản; iii) marketing; iv) liên kết đối tác; v) hoạt động khác.
									</p>
									<p style={{textAlign: "justify"}}>
									- Chúng tôi có thể tổng hợp thông tin của bạn và người dùng khác của chúng tôi, theo cách mà bạn hoặc bất kỳ ai khác không thể xác định một cách hợp lý và chúng tôi có thể sử dụng bất kỳ thông tin tổng hợp nào như vậy cho bất kỳ mục đích nào.
									</p>
								</div>
								<br />
								<div>
									<h5>3. Chia sẻ dữ </h5>
									<p style={{textAlign: "justify"}} >
									- Chúng tôi có thể chia sẻ dữ liệu cá nhân của bạn với các bên thứ ba nếu chúng tôi cho rằng việc chia sẻ dữ liệu là cần thiết để: i) tuân thủ luật pháp và quy định hiện hành; ii) thực thi các Điều khoản và Điều kiện của chúng tôi; iii) điều tra bất kỳ hành vi gian lận hoặc hành vi bất hợp pháp nào; và / hoặc iv) bảo vệ thương hiệu, uy tín cũng như tài sản của chúng tôi; v) hoàn thành dịch vụ cung cấp cho bạn.
									</p>
									<p style={{textAlign: "justify"}} >
									- Chúng tôi yêu cầu tất cả các bên thứ ba tôn phải trọng quyền riêng tư của bạn và xử lý thông tin của bạn theo đúng quy định của pháp luật. Chúng tôi chỉ cho phép các bên thứ ba xử lý dữ liệu cá nhân của bạn cho các mục đích cụ thể và theo thỏa thuận của chúng tôi với họ.
									</p>
								</div>
								<br />
								<div>
									<h5>4. Bảo vệ dữ liệu của bạn</h5>
									<p style={{textAlign: "justify"}} >
									Chúng tôi bảo vệ Dữ liệu Cá nhân bằng cách duy trì các thỏa thuận bảo mật hợp lý, bao gồm các quy trình vật lý, kỹ thuật và tổ chức, để ngăn chặn truy cập trái phép, thu thập, sử dụng, tiết lộ, sao chép, sửa đổi, xử lý hoặc rủi ro tương tự. Nếu phát hiện bất kỳ vi phạm nào, chúng tôi sẽ thông báo tới bạn thông qua các kênh của chúng tôi, để thông báo tới bạn thông tin đầy đủ nhất về việc vi phạm đó. Theo thông báo này, chúng tôi sẽ nổ lực hết sức để khôi phục tính bảo mật dữ liệu của bạn trên hệ thống của chúng tôi trong thời gian sớm nhất.
									</p>
								</div>
								<br />
								<div>
									<h5>5. Chính sách Cookie</h5>
									<p style={{textAlign: "justify"}} >
									- Một cookie là một nhật ký định danh chữ và số mà chúng tôi chuyển vào ổ cứng hoặc thẻ nhớ thông qua trình duyệt web của bạn khi bạn truy cập website của chúng tôi. Nó cho phép hệ thống của chúng tôi nhận ra bạn khi bạn truy cập lại website của chúng tôi và cải thiện dịch vụ của chúng tôi cho bạn. Thông tin được sử dụng để theo dõi việc sử dụng của người dùng truy cập website và để tổng hợp các báo cáo thống kê về hoạt động của website. Để biết thêm thông tin về cookie, hãy truy cập <a href="www.aboutcookies.org" target="_blank">www.aboutcookies.org</a> hoặc <a href="www.allaboutcookies.org" target="_blank">www.allaboutcookies.org</a>.
									</p>
									<p style={{textAlign: "justify"}} >
									- Cookies cũng có thể được sử dụng để biên dịch thông tin tổng hợp về các khu vực trên website của chúng tôi được truy cập thường xuyên nhất. Thông tin lưu lượng này có thể được sử dụng để nâng cao nội dung của website của chúng tôi và giúp bạn sử dụng nó dễ dàng hơn. Nếu bạn muốn từ chối cookie của chúng tôi, bạn có thể định cấu hình trình duyệt của mình để làm như vậy. Tuy nhiên, một số tính năng website của chúng tôi có thể không hoạt động nếu bạn xóa cookie khỏi trình duyệt của mình. Nếu bạn không bật cookie, bạn chỉ có thể sử dụng website trên cơ sở hạn chế.
									</p>
								</div>
								<br />
								<div>
									<h5>6. Liên kết với website khác</h5>
									<p style={{textAlign: "justify"}} >
										Website của tôi có thể chứa hoặc sử dụng liên kết với các website khác. Tuy nhiên, khi bạn đã sử dụng các liên kết này để rời khỏi website của chúng tôi, bạn nên lưu ý rằng chúng tôi không có bất kỳ quyền kiểm soát nào đối với website khác đó. Xin lưu ý rằng chúng tôi không chịu trách nhiệm về chính sách quyền riêng tư hoặc điều khoản của các website khác đó và bạn nên đọc kỹ chính sách quyền riêng tư của mỗi website bạn truy cập.
									</p>
								</div>
								<br />
								<div>
									<h5>7. Huỷ bỏ cam kết</h5>
									<p style={{textAlign: "justify"}} >
									Bạn có thể huỷ bỏ đồng ý về chính sách của chúng tôi về việc sử dụng thông tin cá nhân của bạn bất cứ lúc nào bằng cách cung cấp lý do hợp lý.
									Nếu bạn rút thoả thuần, vui lòng liên hệ với chúng tôi để được hỗ trợ các thủ tục liên quan.
									Lưu ý rằng khi rút khỏi thoả thuận này, bạn không thể tiếp tục sử dụng dịch vụ của chúng tôi cung cấp và bạn đồng ý rằng chúng tôi sẽ không chịu trách nhiệm với bạn về bất kỳ tổn thất hoặc thiệt hại nào phát sinh từ hoặc liên quan đến việc chấm dứt này.
									</p>
								</div>
								<br />
								<div>
									<h5>8. Điều khoản khác</h5>
									<p style={{textAlign: "justify"}} >
									- Trong trường hợp không đồng nhất giữa điều khoản sử dụng và chính sách bảo mật này, chúng tôi sẽ ưu tiên chính sách bảo mật này.
									</p>
									<p style={{textAlign: "justify"}} >
									- Trong trường hợp chuyển giao doanh nghiệp, dữ liệu cá nhân của bạn trên website này có thể là một phần tài sản chuyển giao.
									</p>
								</div>
								<br />
								<div>
									<h5>9. Thông tin liên lạc</h5>
									<p style={{textAlign: "justify"}} >
										Nếu bạn có bất cứ câu hỏi hoặc phản hồi nào về chính sách bảo mật này, vui lòng liên hệ với chúng tôi theo email: <a href="mailto:contact.topauto@gmail.com?Subject=Hello" target="_top">contact.topauto@gmail.com</a> hoặc <a href="http://topauto.vn/contact">tại đây</a>.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default Privacy;