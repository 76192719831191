import React, {Component} from 'react'
import { translate, Trans } from "react-i18next";
import {Helmet} from "react-helmet"
import SearchBox from '../../components/garages/SearchBox'
import NewPostListings from '../../components/posts/NewPostListings'
import TopGarages from '../../components/garages/TopGarages'
import GaragesInCity  from '../../components/garages/GaragesInCity'
import GarageImageGallery from '../../components/garages/GarageImageGallery'
import Carousel from 'react-bootstrap/Carousel'


import {NEW_POST_IN_CITY_LIST} from '../../config/mocks'

// import ReactCountdownClock from 'react-countdown-clock'
class Garages extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		const {t} = this.props
		return (
			<React.Fragment>
				<Helmet>
					{/*facebook*/}
					<title> {"Tìm kiếm thông tin salon ô tô tại Việt Nam"} </title>
					<meta property="og:title" content="Tìm kiếm thông tin salon tại Việt Nam" />
					<meta property="og:description" content="Tìm kiếm thông tin salon tại Việt Nam"/>
					<meta property="og:image" content="http://topauto.vn/images/logo.svg"/>
					<meta property="og:url" content="http://topauto.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content="Tìm kiếm thông tin salon tại Việt Nam"/>
					<meta name="twitter:description" content="Tìm kiếm thông tin salon tại Việt Nam"/>
					<meta name="twitter:image" content="http://topauto.vn/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
				</Helmet>
				<div className="container">
					<div className="row m-3">
						<div className="col-md-4">
							<h3>{t('find_vehicles')}</h3>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<SearchBox />
						</div>
					</div>
					<div className="row">
						<GarageImageGallery />
					</div>
				</div>
				<TopGarages items={NEW_POST_IN_CITY_LIST}/>
				<GaragesInCity cityName={"Hồ Chí Minh"} items={NEW_POST_IN_CITY_LIST}/>
				<GaragesInCity cityName={"Hà Nội"} items={NEW_POST_IN_CITY_LIST}/>
				<NewPostListings cityName={"Hồ Chí Minh"} items={NEW_POST_IN_CITY_LIST}/>
			</React.Fragment>
		)
	}
}
export default translate("translations")(Garages)
