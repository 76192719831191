import React, {Component} from 'react'
import HomeSearchBoxSection from '../components/HomeSearchBoxSection'
import NewsLetter from '../components/NewsLetter'
// import ReactCountdownClock from 'react-countdown-clock'
import SearchBox from '../components/posts/SearchBox'
import { translate, Trans } from "react-i18next"
import FeaturedPosts from '../components/posts/FeaturedPosts'
import NewPostListings from '../components/posts/NewPostListings'
import ClientsPrefer from '../components/cities/ClientsPrefer'


import {NEW_POST_IN_CITY_LIST} from '../config/mocks'

class Home extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		const {t} = this.props
		return (
			<React.Fragment>
				<main role="main">
					<div className="container">
						<div className="row m-3 p-0">
							<h3>{t('find_vehicles')}</h3>
						</div>
						<div className="row m-3">
							<div className="col-12 p-0">
								<SearchBox />
							</div>
						</div>
					</div>
					<FeaturedPosts items={NEW_POST_IN_CITY_LIST}/>
					<ClientsPrefer items={[]}/>
					<NewPostListings cityName={"Hồ Chí Minh"} items={NEW_POST_IN_CITY_LIST}/>
					<NewsLetter />
				</main>
			</React.Fragment>
		)
	}
}
export default translate("translations")(Home);