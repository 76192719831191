import React, {Component} from 'react'
import { translate } from "react-i18next"
import FieldDropdownItem from '../searches/FieldDropdownItem'
import {MANUFACTURER_LIST, CITY_RANKING_LIST} from '../../config/mocks'

class SearchBox extends Component {
	constructor(props) {
		super(props)
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	handleSubmit(event){
		event.preventDefault()
	}
	render (){
		const {t} = this.props
		return (
			<React.Fragment>
				<div className="container search-box">
					<div className="row pt-4 pb-2">
						<div className="col-md-3 col-12">
							<FieldDropdownItem title={t('city')} items={[{id:-1, name: 'Tất cả'}, ...CITY_RANKING_LIST]}/>
						</div>
						<div className="col-md-3 col-6">
							<FieldDropdownItem title={t('district')} items={[{id:-1, name: 'Tất cả'}]}/>
						</div>
						<div className="col-md-3 col-6">
							<FieldDropdownItem title={t('ward')} items={[{id:-1, name: 'Tất cả'}]}/>
						</div>
						<div className="col-md-3 col-12">
							<div className="form-group">
								<button type="button" className="btn btn-danger btn-block">{t('search')}</button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default translate("translations")(SearchBox)