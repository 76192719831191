import React, {Component} from 'react'
import { translate, Trans } from "react-i18next";
import {Helmet} from "react-helmet"
import GoogleMapReact from 'google-map-react';


import {NEW_POST_IN_CITY_LIST} from '../../config/mocks'

// import ReactCountdownClock from 'react-countdown-clock'
const AnyReactComponent = ({ text }) => <div>{text}</div>
class Parkings extends Component {
	static defaultProps = {
	    center: {
	      lat: 59.95,
	      lng: 30.33
	    },
	    zoom: 11
	};
	constructor(props) {
		super(props)
	}
	render () {
		const {t} = this.props
		return (
			<React.Fragment>
				<Helmet>
					{/*facebook*/}
					<title> {"Tìm kiếm thông tin salon ô tô tại Việt Nam"} </title>
					<meta property="og:title" content="Tìm kiếm thông tin salon tại Việt Nam" />
					<meta property="og:description" content="Tìm kiếm thông tin salon tại Việt Nam"/>
					<meta property="og:image" content="http://topauto.vn/images/logo.svg"/>
					<meta property="og:url" content="http://topauto.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content="Tìm kiếm thông tin salon tại Việt Nam"/>
					<meta name="twitter:description" content="Tìm kiếm thông tin salon tại Việt Nam"/>
					<meta name="twitter:image" content="http://topauto.vn/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
				</Helmet>
				<div className="container">
					<div className="row m-3">
						<div className="col-md-4">
							<h3>{t('find_vehicles')}</h3>
						</div>
					</div>
				</div>
				<div className="container">
					<div style={{ height: '100vh', width: '100%' }}>
				        <GoogleMapReact
				          bootstrapURLKeys={{ key: "AIzaSyCciBLb8DpANO0ltCE9xJ3K0RktaPXnpcU" }}
				          defaultCenter={this.props.center}
				          defaultZoom={this.props.zoom}
				        >
				          <AnyReactComponent
				            lat={59.955413}
				            lng={30.337844}
				            text="My Marker"
				          />
				        </GoogleMapReact>
				      </div>
				</div>
			</React.Fragment>
		)
	}
}
export default translate("translations")(Parkings)
