import React, {Component} from 'react'
import Select from '../Select'

class FieldInputCheck extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		const title = this.props.title
		return (
			<React.Fragment>
				<div className="form-check ml-3">
					<input className="form-check-input search_form_check_input--red" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked/>
					<label className="form-check-label" for="exampleRadios1">
						{title}
					</label>
				</div>
			</React.Fragment>
		)
	}
}
export default FieldInputCheck