import React, {Component} from 'react'

class Pagination extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		return (
			<React.Fragment>
				<ul className="pagination">
					<li className="listings_nav_item active"><a href="#">1</a></li>
					<li className="listings_nav_item"><a href="#">2</a></li>
					<li className="listings_nav_item"><a href="#">3</a></li>
					<li className="listings_nav_item"><a>&gt;</a></li>
				</ul>
			</React.Fragment>
		)
	}
}
export default Pagination