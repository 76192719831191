import React, { Component } from 'react'
import { translate, Trans } from "react-i18next";

import Select from './Select'


class SearchBoxHome extends Component {
	constructor(props) {
    	super(props)
        this.handleSubmit = this.handleSubmit.bind(this)

	}
	handleSubmit(event){

		//
		event.preventDefault()

  	}
	render (){
		const {t} = this.props
		return (
			<div className="section">
				<div className='container'>
					<div className='row'>
						<div className='col'>
							<div className="home_search_box_inner">
								<h3>
									{t('search_your_auto')}
								</h3>
								<form className="section_search_box" onSubmit={this.handleSubmit}>
									<div className="home_search_box_container">
										<ul className="search_dropdown_row clearfix">
											<li className="search_dropdown_item search_dropdown_item_5">
												<div className="home_dropdown_item_title">{t('methods')}</div>
												<Select classes={"home_dropdown_item_select"} items={[]} />
											</li>
											<li className="search_dropdown_item search_dropdown_item_5">
												<div className="home_dropdown_item_title">{t('property_type')}</div>
												<Select classes={"home_dropdown_item_select"} items={[]} />
											</li>
											<li className="search_dropdown_item search_dropdown_item_5">
												<div className="home_dropdown_item_title">{t('city')}</div>
												<Select classes={"home_dropdown_item_select"} items={[]} />
											</li>
											<li className="search_dropdown_item search_dropdown_item_5">
												<div className="home_dropdown_item_title">{t('district')}</div>
												<Select classes={"home_dropdown_item_select"} items={[]} />
											</li>
											<li className="search_dropdown_item search_dropdown_item_5">
												<div className="home_dropdown_item_title">{t('ward')}</div>
												<Select classes={"home_dropdown_item_select"} items={[]} />
											</li>
										</ul>
									</div>

									<div className="home_search_box_container">
										<ul className="search_dropdown_row clearfix">
											<li className="home_dropdown_item home_dropdown_item_6">
												<div className="home_dropdown_item_title">{t('bedrooms_no')}</div>
												<Select classes={"home_dropdown_item_select"} items={[]} />
											</li>
											<li className="home_dropdown_item home_dropdown_item_6">
												<div className="home_dropdown_item_title">{t('bathrooms_no')}</div>
												<Select classes={"home_dropdown_item_select"} items={[]} />
											</li>
											<li className="home_dropdown_item home_dropdown_item_6">
												<div className="home_dropdown_item_title">{t('min_price')}</div>
												<Select classes={"home_dropdown_item_select"} items={[]} />
											</li>
											<li className="home_dropdown_item home_dropdown_item_6">
												<div className="home_dropdown_item_title">{t('max_price')}</div>
												<Select classes={"home_dropdown_item_select"} items={[]} />
											</li>
											<li className="home_dropdown_item home_dropdown_item_6">
												<div className="home_dropdown_item_title">{t('min_sq ft')}</div>
												<Select classes={"home_dropdown_item_select"} items={[]} />
											</li>
											<li className="home_dropdown_item home_dropdown_item_6">
												<div className="home_dropdown_item_title">{t('max_sq_ft')}</div>
												<Select classes={"home_dropdown_item_select"} items={[]} />
											</li>
											<li className="home_dropdown_item">
												<div className="home_search_button">
													<input value={t('search')} type='submit' className="home_search_submit_button"></input>
												</div>
											</li>
										</ul>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>		
			</div>
		)
	}
}

export default translate("translations")(SearchBoxHome)

///<Link className='btn search_submit_button' to='/listings'>search</Link>
///<input value='search' type='submit' className='search_submit_button'></input>
/*
<div className={style.search_arrow_box}>
										<div className={style.search_arrow_box_inner}>
											<div className={`${style.search_arrow_circle} d-flex flex-column align-items-center justify-content-center`}><span>{t('search_it_here')}</span></div>
											<img src='images/search_arrow.png' alt='' />
										</div>
									</div>
*/