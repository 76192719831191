import React from 'react'
import { Switch as RouterSwitch, Route } from 'react-router-dom'
import Home from './pages/Home'
import Features from './pages/Features'
import FreeTools from './pages/FreeTools'
import FAQ from './pages/FAQ'
import Contact from './pages/Contact'
import NotFound from './pages/errors/NotFound'
import QRCodeDesignerTool from './pages/freetools/QRCodeDesignerTool'
import AboutUs from './pages/AboutUs'
import Terms from './pages/Terms'
import Privacy from './pages/Privacy'
import ComingSoon from './pages/ComingSoon'
import PostListings from './pages/posts/Listings'
import PostDetails from './pages/posts/PostDetails'

import Salons from './pages/salons/Salons'
import Garages from './pages/garages/Garages'
import Parkings from './pages/parkings/Parkings'


const Main = ({props}) => (
	<RouterSwitch>
		<Route exact path="/" component={Home}/>
		<Route exact path="/home" component={Home}/>
		<Route exact path="/features" component={Features}/>
		<Route exact path="/listings" component={PostListings}/>
		<Route exact path="/listings/:id" component={PostDetails}/>
		<Route exact path="/salons" component={Salons}/>
		<Route exact path="/garages" component={Garages}/>
		<Route exact path="/parkings" component={Parkings}/>
		<Route exact path="/resources/free-tools" component={FreeTools}/>
		<Route exact path="/free-tools/qr-code-generator" component={QRCodeDesignerTool}/>
		<Route exact path="/investment" component={FAQ}/>
		<Route exact path="/faq" component={FAQ}/>
		<Route exact path="/contact" component={Contact}/>
		<Route exact path="/about" component={AboutUs}/>
		<Route exact path="/terms" component={Terms}/>
		<Route exact path="/privacy" component={Privacy}/>
		<Route exact path="/coming-soon" component={ComingSoon}/>
		<Route component={NotFound}/>
	</RouterSwitch>
)
export default Main;