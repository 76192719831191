import React from 'react'
import { translate } from 'react-i18next'
import GarageList from './GarageList'
const TopGarages = ({t, items}) => (
	<div className="section">
		<div className="container">
			<div className="row">
				<div className="col">
					<div className="section_title text-center">
						<h3>{t('top_salons')}</h3>
						<a href="#" className="section_subtitle_a">{t('view_all')}</a>
          			</div>
        		</div>
			</div>
		</div>
		<GarageList items={items}/>
	</div>
)
export default translate("translations")(TopGarages)