import React, { Component } from 'react'
import { translate, Trans } from 'react-i18next'
import FieldDropdownItem from '../searches/FieldDropdownItem'
import FieldInputCheck from '../searches/FieldInputCheck'

class SearchBox extends Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	handleSubmit(event){
		event.preventDefault()
	}
	render (){
		const {t} = this.props
		return (
			<React.Fragment>
				<div className="container search-box">
					<div className="row pt-4 pb-2">
						<FieldInputCheck title={t('all')}/>
						<FieldInputCheck title={t('vehicle_new')}/>
						<FieldInputCheck title={t('vehicle_used')}/>
					</div>
					<div className="row">
						<div className="col-md-3">
							<FieldDropdownItem title={t('manufacturer')} items={[{id:-1, name: 'Tất cả'}, {id:-1, name: 'Toyota'}]}/>
						</div>
						<div className="col-md-3">
							<FieldDropdownItem title={t('vehicle_model')} items={[{id:-1, name: 'Tất cả'}]}/>
						</div>
						<div className="col-md-3">
							<FieldDropdownItem title={t('year')} items={[{id:-1, name: 'Tất cả'}]}/>
						</div>
						<div className="col-md-3">
							<FieldDropdownItem title={t('transmission')} items={[{id:-1, name: 'Tất cả'}]}/>
						</div>
					</div>
					<div className="row pb-2">
						<div className="col-md-3 col-6">
							<FieldDropdownItem title={t('price_from')} items={[{id:-1, name: 'Tất cả'}]}/>
						</div>
						<div className="col-md-3 col-6">
							<FieldDropdownItem title={t('price_to')} items={[{id:-1, name: 'Tất cả'}]}/>
						</div>
						<div className="col-md-3 col-12">
							<FieldDropdownItem title={t('city')} items={[{id:-1, name: 'Tất cả'}]}/>
						</div>
						<div className="col-md-3 col-12">
							<div className="form-group">
								<button type="button" className="btn btn-danger btn-block">{t('search')}</button>
								<label className="search-from__more_title">{ '+ ' + t('more_criteria')}</label>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default translate("translations")(SearchBox)
