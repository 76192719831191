import React from 'react'
import { translate } from 'react-i18next'
const GarageImageGallery = ({t, items}) => (
	<div className="container" style={ {maxHeight: '450px'} }>
		<div className="row">
			<div className="col-md-10 col-12">
				<img className="img-fluid" src="/images/home_slider_bcg.jpg"/>
			</div>
			<div className="col-md-2 col-12">
				<img className="img-thumbnail border-0" src="/images/home_slider_bcg.jpg"/>
				<img className="img-thumbnail border-0" src="/images/home_slider_bcg.jpg"/>
				<img className="img-thumbnail border-0" src="/images/home_slider_bcg.jpg"/>
				<img className="img-thumbnail border-0" src="/images/home_slider_bcg.jpg"/>
				<img className="img-thumbnail border-0" src="/images/home_slider_bcg.jpg"/>
			</div>
		</div>
	</div>
)
export default translate("translations")(GarageImageGallery)