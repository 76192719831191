import React from 'react'
import { translate } from "react-i18next"

function getYear () {
	return new Date().getFullYear()
}
const Footer = ({t, props}) => (
	<footer className="footer-area">
        <div className="container">
            <div className="row">
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <a href="/" className="foo-logo"><img src="images/logo.png" alt="TopAuto Logo"/></a>
                        <p>{t('the_top_auto_is_a_auto_service_...')}</p>
                        <div className="footer-social-info">
                            <a href="#" className="facebook"><i className="fab fa-facebook-f"></i></a>
                            <a href="#" className="google-plus"><i className="fab fa-google-plus-g"></i></a>
                            <a href="#" className="instagram"><i className="fab fa-instagram"></i></a>
                            <a href="#" className="twitter"><i className="fab fa-twitter"></i></a>
                            <a href="#" className="linkedin"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">{t('account')}</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/advertise"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {t('submit_ads')}</a></li>
                                <li><a href="/register"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {t('register')}</a></li>
                                <li><a href="/login"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {t('login')}</a></li>
                                <li><a href="http://account.topreal.vn" target="_blank"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {"My TopAuto"}</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">{t('useful_links')}</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/listings"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {t('listings')}</a></li>
                                <li><a href="/news"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {t('news')}</a></li>
                                <li><a href="/companies?src=ulink&catId=2"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {t('constructors')}</a></li>
                                <li><a href="/companies?src=ulink&catId=8"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {t('our_agents')}</a></li>
                                <li><a href="/about"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {t('about_us')}</a></li>
                                <li><a href="/about"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {"Sitemap"}</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">Kênh đầu tư</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/coming-soon"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Dự án đất nền</a></li>
                                <li><a href="/coming-soon"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Căn hộ chung cư</a></li>
                                <li><a href="/coming-soon"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Khu nghỉ dưỡng</a></li>
                                <li><a href="/coming-soon"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Chia sẻ</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">Mua bán Ô Tô</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/listings/qlink/ban-nha-quan-2?src=qlink&qm=1&qcaid=2&qcid=51&qdid=567"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Mua ô tô củ</a></li>
                                <li><a href="/listings/qlink/ban-nha-dat-quan-7?src=qlink&qm=1&qcaid=2&qcid=51&qdid=576"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Mua ô tô mới</a></li>
                                <li><a href="/listings/qlink/ban-nha-binh-chanh?src=qlink&qm=1&qcaid=2&qcid=51&qdid=579"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Mua ô tô trả góp</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">Thuê xe Ô Tô</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/listings/qlink/ban-nha-dat-quan-cau-giay-hanoi?src=qlink&qm=1&qcaid=2&qcid=2&qdid=7"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Thuê xe tự lái</a></li>
                                <li><a href="/listings/qlink/ban-nha-dat-quan-dong-da-hanoi?src=qlink&qm=1&qcaid=2&qcid=2&qdid=8"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Thuê xe tháng</a></li>
                                <li><a href="/listings/qlink/ban-nha-dat-quan-ba-dinh-hanoi?src=qlink&qm=1&qcaid=2&qcid=2&qdid=9"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Thuê xe hợp đồng</a></li>
                                <li><a href="/listings/qlink/ban-nha-dat-quan-ba-dinh-hanoi?src=qlink&qm=1&qcaid=2&qcid=2&qdid=9"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Đi chung xe</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">Xe Toyota</h6>

                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/listings/qlink/mua-ban-nha-rieng?src=qlink&qm=1&qcaid=2"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Toyota Wigo</a></li>
                                <li><a href="/listings/qlink/mua-ban-can-ho?src=qlink&qm=1&qcaid=1"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Toyota Rush</a></li>
                                <li><a href="/listings/qlink/mua-ban-dat-nen-long-an?src=qlink&qcaid=5&qcid=52"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Toyota Vios</a></li>
                                <li><a href="/listings/qlink/mua-ban-dat-nen-binh-duong?src=qlink&qcaid=5&qcid=48"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Toyota Innova</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">Xe Ford</h6>
                       <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/listings/qlink/cho-thue-nha-tro-phong-tro?src=qlink&qm=2&qcaid=14" ><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Ford Ranger</a></li>
                                <li><a href="/listings/qlink/cho-thue-can-ho-chung-cu?src=qlink&qm=2&qcaid=10" ><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Ford Ecosport</a></li>
                                <li><a href="/listings/qlink/cho-thue-can-van-phong?src=qlink&qm=2&qcaid=15" ><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Ford Everest</a></li>
                                <li><a href="/listings/qlink/cho-thue-can-van-phong?src=qlink&qm=2&qcaid=11" ><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Ford Focus</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">XE BMW</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/projects?catId=1"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe BMW X5</a></li>
                                <li><a href="/projects?catId=10"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe BMW X6</a></li>
                                <li><a href="/projects?catId=11"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe BMW 320i</a></li>
                                <li><a href="/projects?catId=11"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe BMW 520i</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">XE MERCEDES</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/companies?src=qlink&catId=2"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Mercedes C200</a></li>
                                <li><a href="/companies?src=qlink&catId=2"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Mercedes C300</a></li>
                                <li><a href="/companies?src=qlink&catId=5"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Mercedes C250</a></li>
                                <li><a href="/companies?src=qlink&catId=3"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Mercedes E200</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">XE HONDA</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/projects?catId=1"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Honda City</a></li>
                                <li><a href="/projects?catId=10"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Honda Civic</a></li>
                                <li><a href="/projects?catId=11"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Honda CR-V</a></li>
                                <li><a href="/projects?catId=11"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Honda HR-V</a></li>

                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">XE KIA</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/companies?src=qlink&catId=2"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Kia Morning</a></li>
                                <li><a href="/companies?src=qlink&catId=5"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Kia Cerato</a></li>
                                <li><a href="/companies?src=qlink&catId=3"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Kia Sedona</a></li>
                                <li><a href="/companies?src=qlink&catId=3"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Kia Sorento</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">XE HYUNDAI</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/projects?catId=1"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Hyundai Accent</a></li>
                                <li><a href="/projects?catId=10"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Hyundai Kona</a></li>
                                <li><a href="/projects?catId=11"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Hyundai Alantra</a></li>
                                <li><a href="/projects?catId=11"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Hyundai Grand i10</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">XE MAZDA</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/companies?src=qlink&catId=2"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Mazda 2</a></li>
                                <li><a href="/companies?src=qlink&catId=5"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Mazda 3</a></li>
                                <li><a href="/companies?src=qlink&catId=3"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Mazda 6</a></li>
                                <li><a href="/companies?src=qlink&catId=3"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Xe Mazda CZ5</a></li>

                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">Liên kết</h6>
                        <nav className="footer-widget-nav">
                       
                            <ul>
                                <li><a href="http://toplabs.io" target="_blank"><i className="fa fa-angle-double-right" aria-hidden="true"></i> TopLabs</a></li>
                                <li><a href="http://topreal.vn" target="_blank"><i className="fa fa-angle-double-right" aria-hidden="true"></i> TopReal</a></li>
                                <li><a href="http://zetgoo.com" target="_blank"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Zetgoo</a></li>
                                <li><a href="http://thinktree.zetgoo.com" target="_blank"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Tiên Tri</a></li>
                                <li><a href="http://skeleton.zetgoo.com" target="_blank"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Công cụ</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">{t('contact_info')}</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li className="contact_info_item d-flex flex-row">
                                <div><div className="contact_info_icon"><img src="images/placeholder.svg" alt=""></img></div></div>
                                <div className="contact_info_text">{t('topauto_address')}</div>
                            </li>
                            <li className="contact_info_item d-flex flex-row">
                                <div><div className="contact_info_icon"><img src="images/phone-call.svg" alt=""></img></div></div>
                                <div className="contact_info_text">+8490 866 77 96</div>
                            </li>
                            <li className="contact_info_item d-flex flex-row">
                                <div><div className="contact_info_icon"><img src="images/phone-call.svg" alt=""></img></div></div>
                                <div className="contact_info_text">+8498 243 71 72</div>
                            </li>
                            <li className="contact_info_item d-flex flex-row">
                                <div><div className="contact_info_icon"><img src="images/message.svg" alt=""></img></div></div>
                                <div className="contact_info_text"><a href="mailto:contact.topauto@gmail.com?Subject=Hello" target="_top">contact.topauto@gmail.com</a></div>
                            </li>
                            <li className="contact_info_item d-flex flex-row">
                                <div><div className="contact_info_icon"><img src="images/planet-earth.svg" alt=""></img></div></div>
                                <div className="contact_info_text"><a href="https://topauto.vn">http://topauto.vn</a></div>
                            </li>
                            </ul>
                        </nav>
                    </div>
                </div>

            </div>
        </div>
        <div className="copywrite-area">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <p className="copywrite-text">
Bản quyền  ©  {getYear()} Tất cả được bảo vệ | Phát triển bởi <i className="far fa-heart" aria-hidden="true"></i> <a href="http://toplabs.io" target="_blank">TopLabs</a></p>
                    </div>
                    <div className="col-12 col-sm-6">
                        <nav className="footer-nav">
                            <ul>
                                <li><a href="/">Trang chủ</a></li>
                                <li><a href="/terms">Điều khoản</a></li>
                                <li><a href="/privacy">Quyền riêng tư</a></li>
                                <li><a href="/advertise">Quảng cáo</a></li>
                                <li><a href="/contact">Liên hệ</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </footer>
)
export default translate("translations")(Footer)