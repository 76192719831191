import React, { Component } from 'react'
import { translate, Trans } from "react-i18next";


class PostListItem extends Component {
	constructor(props) {
		super(props)
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	handleSubmit(event){
		event.preventDefault()
	}
	render (){
		const {t, item} = this.props
		return (
			<div className="post-listings-item">
				<img className="post-listings-item__thumbnail d-flex flex-wrap" src={item.imageURL} alt="Card image cap"/>
				<div className="post-listings-item__body">
					<div className="attributes">
					</div>
					<div className="address">
						<address>
							{item.address}
						</address>
					</div>
					<p className="title">{item.title}</p>
					<time></time>
					<div className="post-listings-item__body--price">
						{item.price}
					</div>
				</div>
			</div>
		)
	}
}

export default translate("translations")(PostListItem)