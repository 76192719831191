import React, {Component} from 'react'
import { translate, Trans } from "react-i18next";
import {Helmet} from "react-helmet"
import PostsByAdvertiser from '../../components/posts/PostsByAdvertiser'

import NewsLetter from '../../components/NewsLetter'


import {POST_LIST} from '../../config/mocks'

// import ReactCountdownClock from 'react-countdown-clock'
class PostDetails extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		return (
			<React.Fragment>
				<Helmet>
					{/*facebook*/}
					<title> {"Tìm kiếm thông tin mua bán xe hơi tại Việt Nam"} </title>
					<meta property="og:title" content="Tìm kiếm thông tin mua bán xe hơi tại Việt Nam" />
					<meta property="og:description" content="Tìm kiếm thông tin mua bán xe hơi tại Việt Nam"/>
					<meta property="og:image" content="http://topauto.vn/images/logo.svg"/>
					<meta property="og:url" content="http://topauto.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content="Tìm kiếm thông tin mua bán xe hơi tại Việt Nam"/>
					<meta name="twitter:description" content="Tìm kiếm thông tin mua bán xe hơi tại Việt Nam"/>
					<meta name="twitter:image" content="http://topauto.vn/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
				</Helmet>
				<div className="container">
					<div className="row">
						<div className="col-lg-8">
							<div className="row">
								<div className="col">
								</div>
							</div>
							<div className="row">
								<div className="col">
								</div>
							</div>
							<div className="row">
								<div className="col">
								</div>
							</div>
						</div>
						<div className="col-lg-4">
						</div>
					</div>
				</div>
				<PostsByAdvertiser items={POST_LIST}/>
				<NewsLetter />
			</React.Fragment>
		)
	}
}
export default translate("translations")(PostDetails)